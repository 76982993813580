<script setup lang="ts">
import { storeToRefs } from 'pinia';
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import { REFFERAL_LINK, COPY, COPY_INTO_CLIPBOARD } from '@/locales/constants';
import { PrimeButton, PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { notify } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useUserStore } from '@/stores/user';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const userStore = useUserStore();
const { refLink } = storeToRefs(userStore);

const handleCopy = () => {
	navigator.clipboard.writeText(refLink.value);
	notify({
		body: `${t(COPY_INTO_CLIPBOARD)}: ${refLink.value}`,
	});
};
</script>

<template>
	<ModalCommonContent class="refferal-link-modal">
		<template #header>{{ $t(REFFERAL_LINK) }}</template>
		<form class="refferal-link-modal-content" @submit.prevent="handleCopy">
			<PrimeText weight="500" color="white" class="refferal-link-teaser">
				{{ refLink }}
			</PrimeText>
			<PrimeButton
				:label="$t(COPY)"
				class="refferal-link-btn"
				role="submit"
				transform="capitalize"
			/>
		</form>
	</ModalCommonContent>
</template>

<style scoped lang="scss">
.refferal-link-modal-content {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-top: 1.5rem;
	padding-bottom: 0.1rem;
}
.refferal-link-teaser {
	margin-bottom: 0.5rem;
	display: inline-block;
	text-align: center;
}
.refferal-link-btn {
	align-self: center;
	margin-top: 1.5rem;
}
</style>
