import { API } from '@/api/api-service';
import type { Paginated } from '@/types';

export default API;

export * from './auth';
export * from './country';
export * from './leaderboard';
export * from './notifications';
export * from './prize-draw';
export * from './profile';
export * from './referrals';
export * from './rewards';
export * from './transactions';
export * from './users';
export * from './welcome-bonus';
export * from './announcements';
export * from './offerwalls';
export * from './app';
export * from './mobile';
export * from './bonus-code';

export const getPaginatedData = async <T>(
	apiUrl: string,
	offset: number,
	page: number
): Promise<Paginated<T[]>> => {
	const { data } = await API.get<Paginated<T[]>>(apiUrl, {
		params: { per_page: offset, page },
	});

	return data;
};
