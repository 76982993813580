import { USER_ROUTE_NAME } from '@/constants/routes';
import {
	checkContextIdHook,
	checkEmailSubscription,
	checkInitializedClaim,
	showAdjoeOfferwall,
} from '@/router/hooks';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';

export const userRoutes = [
	{
		path: '/surveys',
		name: USER_ROUTE_NAME.SURVEYS,
		component: () => import('@/views/surveys/SurveysView.vue'),
		beforeEnter: checkContextIdHook,
	},
	{
		path: '/profile',
		name: USER_ROUTE_NAME.PROFILE,
		component: () => import('@/views/profile/ProfileView.vue'),
	},
	{
		path: '/settings',
		name: USER_ROUTE_NAME.SETTINGS,
		component: () => import('@/views/settings/SettingsView.vue'),
		meta: {
			hideMobileBalance: true,
		},
	},
	{
		path: '/accept-email-transfer',
		name: USER_ROUTE_NAME.ACCEPT_EMAIL_TRANSFER,
		component: () => import('@/views/settings/SettingsView.vue'),
		meta: {
			isPublic: true,
			withoutHeader: true,
			ignoreAuth: true,
		},
	},
	{
		path: '/reward-history/rewards',
		name: USER_ROUTE_NAME.REWARD_HISTORY,
		component: () => import('@/views/history/views/rewards/RewardsView.vue'),
		meta: {
			hideMobileBalance: true,
		},
	},
	{
		path: '/reward-history/transactions',
		name: USER_ROUTE_NAME.TRANSACTIONS_HISTORY,
		component: () =>
			import('@/views/history/views/transactions/TransactionsView.vue'),
		meta: {
			hideMobileBalance: true,
		},
	},
	{
		path: '/leaderboard',
		name: USER_ROUTE_NAME.LEADERBOARD,
		component: () => import('@/views/leaderboard/LeaderboardView.vue'),
	},
	{
		path: '/confirm-claim',
		name: USER_ROUTE_NAME.CONFIRM_CLAIM,
		component: () => import('@/views/confirm-claim/ConfirmClaimView.vue'),
		beforeEnter: checkInitializedClaim,
		meta: {
			hideMobileBalance: true,
		},
	},
	{
		path: '/prize-draw',
		name: USER_ROUTE_NAME.PRIZE_DRAW,
		component: () => import('@/views/prize-draw/PrizeDrawView.vue'),
	},
	{
		path: '/referrals',
		name: USER_ROUTE_NAME.REFERRALS,
		component: () => import('@/views/referrals/ReferralsView.vue'),
		meta: {
			hideMobileBalance: true,
		},
	},
	{
		path: '/recent-activity',
		name: USER_ROUTE_NAME.RECENT_ACTIVITY,
		component: () => import('@/views/IntegrationSinglePageView.vue'),
		meta: {
			hideMobileBalance: true,
		},
	},
	{
		path: '/support',
		name: USER_ROUTE_NAME.SUPPORT,
		component: () => import('@/views/IntegrationSinglePageView.vue'),
		meta: {
			hideMobileBalance: true,
		},
	},
	{
		path: '/verify-email',
		name: USER_ROUTE_NAME.VERIFY_EMAIL,
		component: () => import('@/views/verify-email/VerifyEmailView.vue'),
	},
	{
		path: '/email-subscriptions',
		name: USER_ROUTE_NAME.EMAIL_SUBSCRIPTIONS,
		beforeEnter: checkEmailSubscription,
		component: () =>
			import('@/views/email-subscriptions/EmailSubscriptionsView.vue'),
		meta: {
			ignoreAuth: true,
			hideMobileBalance: true,
		},
	},
	{
		path: '/offerwalls',
		name: USER_ROUTE_NAME.OFFERWALLS,
		children: [
			{
				path: 'partners',
				name: USER_ROUTE_NAME.OFFERWALLS_PARTNERS,
				component: () =>
					import(
						'@/views/offerwalls/views/partners/OfferwallsPartnersListView.vue'
					),
			},
			{
				path: 'partner/:partnerId',
				name: USER_ROUTE_NAME.OFFERWALLS_PARTNER,
				component: () =>
					import('@/views/offerwalls/views/partner/OfferwallsPartnerView.vue'),
				meta: {
					hideMobileBalance: true,
				},
			},
		],
	},
	{
		path: '/recent-activity-offers',
		name: USER_ROUTE_NAME.RECENT_ACTIVITY_OFFERS,
		component: () => import('@/views/IntegrationSinglePageView.vue'),
		beforeEnter: (
			_to: RouteLocationNormalized,
			_from: RouteLocationNormalized,
			next: NavigationGuardNext
		) => {
			const { isPsOffersAvailable } = storeToRefs(useUserStore());
			if (isPsOffersAvailable.value) {
				next();
			} else {
				next({ name: USER_ROUTE_NAME.SURVEYS });
			}
		},
		meta: {
			hideMobileBalance: true,
		},
	},
	{
		path: '/games',
		name: USER_ROUTE_NAME.GAMES,
		component: () => import('@/views/games/GamesView.vue'),
		beforeEnter: showAdjoeOfferwall,
		meta: {
			progress: true,
		},
	},
];
