<script setup lang="ts">
import { ref, watch } from 'vue';
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import {
	BONUS_CODE,
	BONUS_CODE_MESSAGE,
	SUBMIT,
	BONUS_CODE_SUCCESS,
} from '@/locales/constants';
import {
	PrimeInput,
	PrimeButton,
	PrimeText,
	PrimeDivider,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { getErrorResponse } from '@/utils/helpers';
import { redeemBonusCode } from '@/api';
import { notify } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useI18n } from 'vue-i18n';

const emit = defineEmits<{
	(e: 'close-modal'): void;
}>();

const { t } = useI18n();

const code = ref();
const errorMessage = ref('');
const loading = ref(false);
const isCodeInvalid = ref<'error' | null>(null);

const handleSubmit = async () => {
	try {
		loading.value = true;
		const { status } = await redeemBonusCode(code.value);
		if (status === 200) {
			handleCloseModal();
		}
	} catch (e) {
		errorMessage.value = getErrorResponse(e).message;
		isCodeInvalid.value = 'error';
	} finally {
		loading.value = false;
	}
};

const handleCloseModal = () => {
	emit('close-modal');
	notify({ body: t(BONUS_CODE_SUCCESS) });
};

watch(code, (newValue) => {
	if (isCodeInvalid.value === 'error' && newValue) {
		errorMessage.value = '';
		isCodeInvalid.value = null;
	}
});
</script>

<template>
	<ModalCommonContent class="bonus-code-modal">
		<template #header>{{ $t(BONUS_CODE) }}</template>
		<form class="bonus-code-modal-content" @submit.prevent="handleSubmit">
			<PrimeText
				weight="500"
				color="white"
				align="left"
				class="bonus-code-teaser"
			>
				{{ $t(BONUS_CODE_MESSAGE) }}
			</PrimeText>
			<PrimeInput
				v-model="code"
				type="text"
				:placeholder="$t(BONUS_CODE)"
				:invalid="!!isCodeInvalid"
				:error-text="errorMessage"
				:disabled="loading"
			/>
			<PrimeDivider color="grey-100" top-gap="2.5rem" bottom-gap="1rem" />
			<PrimeButton
				:label="$t(SUBMIT)"
				class="bonus-code-btn"
				role="submit"
				:disabled="!code || !!isCodeInvalid"
				:loading="loading"
			/>
		</form>
	</ModalCommonContent>
</template>

<style scoped lang="scss">
.bonus-code-modal-content {
	display: flex;
	flex-direction: column;
	text-align-last: left;
	width: 100%;
	margin-top: 1.5rem;
}
.bonus-code-teaser {
	margin-bottom: 0.5rem;
	display: inline-block;
}
.bonus-code-btn {
	align-self: center;
}
</style>
