<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import {
	CURRENT_BALANCE,
	POINTS,
	POINTS_TO_START_REDEEM,
} from '@/locales/constants';
import { useUserStore } from '@/stores/user';
import { localizePoints } from '@/utils/helpers';
import { useScreenSize } from '@/utils/composables/useScreenSize';

const { isMobile } = useScreenSize();
const {
	collectedCoins,
	minWelcomeBonusClaimBalance,
	userNotReachedEnoughPoints,
} = storeToRefs(useUserStore());
</script>

<template>
	<div class="reward-info-wrapper">
		<div>
			<PrimeText> {{ $t(CURRENT_BALANCE) }}: </PrimeText>
			<PrimeText color="white" weight="600">
				{{ localizePoints(collectedCoins) }}
				{{ $t(POINTS) }}
			</PrimeText>
		</div>

		<PrimeText
			v-if="userNotReachedEnoughPoints"
			color="secondary"
			:size="isMobile ? 'sm' : 'base'"
		>
			{{
				$t(POINTS_TO_START_REDEEM, {
					value: localizePoints(minWelcomeBonusClaimBalance),
				})
			}}
		</PrimeText>
	</div>
</template>

<style scoped lang="scss">
.reward-info-wrapper {
	display: flex;
	flex-wrap: wrap;
	gap: 0.25rem;
	padding: 0.625rem 0 0.375rem;
}
</style>
